import React from "react";
import { formatNumberWithCommas, safeSum } from "utils/shared";
import momentTimezone from "moment-timezone";
import { LOAN_TYPE_HELOC_SUBSTRING, SECOND_MORT_LIEN_POS } from "./constants";

const formatValue = (value) => {
  const isValidNumber = (checkVal) => {
    return typeof checkVal === "number";
  };
  return isValidNumber(value) ? Math.round(value * 100).toFixed(3) : "-";
};

export const getCalculatedValues = (
  LTV,
  CLTV,
  { isHcltvVisible = false, HCLTV }
) => {
  return (
    <div>{`${formatValue(LTV)} / ${formatValue(CLTV)} ${
      isHcltvVisible ? ` / ${formatValue(HCLTV)}` : ""
    } `}</div>
  );
};

export const getFICO = (borrowers) => {
  return borrowers && borrowers.length ? borrowers[0].creditScore : "";
};

export const formatLoanInformation = ({
  lien,
  loan,
  lockRequestedDate,
  requestLockStatus,
  requestedDate,
  buySideLockExpires,
  isCompareRatesView,
}) => {
  const { borrowers, amount, feeAmountFinanced, financedMIP } = loan;
  const parsedFinancedAmount = parseFloat(feeAmountFinanced) || 0;
  const parsedFinancedMIP = parseFloat(financedMIP) || 0;
  const totalLoanAmount = safeSum(
    amount,
    parsedFinancedAmount,
    parsedFinancedMIP
  );
  const {
    programTypeName = "",
    price,
    commission,
    rate,
    commissionDollar,
    ltv,
    cltv,
    hcltv,
    lockDays,
    lienPosition,
  } = lien;

  const isHcltvVisible =
    lienPosition === SECOND_MORT_LIEN_POS ||
    programTypeName.toUpperCase().includes(LOAN_TYPE_HELOC_SUBSTRING) ||
    !isCompareRatesView;

  if (!isCompareRatesView) {
    return [
      {
        label: "Lock Period",
        value: lockDays ? `${lockDays} Days` : "",
      },
      {
        label: "Term",
        value: programTypeName,
      },
      {
        label: "Total Loan Amount",
        value: `$${formatNumberWithCommas(totalLoanAmount)}`,
      },
      {
        label: `LTV/CLTV${isHcltvVisible ? "/HCLTV" : ""} `,
        value: getCalculatedValues(ltv, cltv, {
          isHcltvVisible,
          HCLTV: hcltv,
        }),
      },
      {
        label: requestLockStatus === 1 ? "Requested Date" : "Locked Date",
        value:
          (requestedDate &&
            momentTimezone
              .tz(requestedDate, "America/New_York")
              .format("MM/DD/YYYY hh:mm A [ET]")) ||
          "",
      },
      {
        label: "Expiration Date",
        value:
          (buySideLockExpires &&
            momentTimezone
              .tz(buySideLockExpires, "America/New_York")
              .format("MM/DD/YYYY hh:mm A [ET]")) ||
          "",
      },
    ];
  }

  return [
    {
      label: "Price",
      value: price ? price.toFixed(3) : "",
    },
    {
      label: "Lock Period",
      value: lockDays ? `${lockDays} Days` : "",
    },
    {
      label: "Term",
      value: programTypeName,
    },

    {
      label: "Total Loan Amount",
      value: `$${formatNumberWithCommas(totalLoanAmount)}`,
    },
    {
      label: "Rate",
      value: rate ? `${rate.toFixed(3)}%` : "",
    },

    {
      label: `LTV/CLTV${isHcltvVisible ? "/HCLTV" : ""} `,
      value: getCalculatedValues(ltv, cltv, {
        isHcltvVisible,
        HCLTV: hcltv,
      }),
    },
    {
      label: "Discount/Rebate Points",
      value: commission ? commission.toFixed(3) : "",
    },
    {
      label: "Discount/Rebate $",
      value: `$${formatNumberWithCommas(Math.round(commissionDollar))}`,
    },
    {
      label: "Loan Date",
      value:
        (lockRequestedDate &&
          momentTimezone
            .tz(lockRequestedDate, "America/New_York")
            .format("MM/DD/YYYY hh:mm A [ET]")) ||
        "",
    },
    {
      label: "FICO",
      value: getFICO(borrowers),
    },
  ];
};
